import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#9e007e",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ee4746",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'Lato', 'sans-serif'",
    button: {
      textTransform: "none",
    },
    body1: {
      fontSize: 16,
      "@media (max-width:460px)": {
        fontSize: 14,
      },
    },
    body2: {
      fontSize: 14,
      "@media (max-width:460px)": {
        fontSize: 12,
      },
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        margin: "0px !important",
        "&:before": {
          backgroundColor: "none",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0px 20px",
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      },
    },
  },
});

export default theme;
